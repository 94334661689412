/* styles for custom elements */

/* overrides */

.overlapping-image .slide-caption.column {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.clapat-button {
  transition: all 0.4s ease-out;
}

.button-disabled {
  opacity: 0.45;
  pointer-events: none;
  transition: all 0.4s ease-out;
}

.text-button span {
  text-decoration: underline;
}

.button-wrap.text-button {
  display: inline-block;
}

#main-page-content {
  height: 100%;
}

.content-row {
  display: flex
}
.content-row.justify-center{
  justify-content: center;
}
ol.bigger li,
ol.bigger li span {
  margin-bottom: 14px;
  line-height: 135%;
}
/* Colors */
.text-green {
  color: rgb(67, 147, 67);
}

/* custom classes */
.link-underline {
  text-decoration: underline;
}

.alert-error {
  color: rgb(255, 0, 64)
}

.dark-section select {
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

select {
  background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
}
select {
  line-height: normal;
}
select {
  color: rgba(0, 0, 0, 1);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  padding: 12px 20px 12px 20px;
  padding-left: 0;
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
  border: none;
  transition: all 0.2s linear;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.align-self-center {
  align-self: center;
}

.page-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-to-bottom.content-to-bottom {  /* this is not a mistake - this iss the trick*/
  margin-top: 15%;
  gap: 14px;
}

.full-height {
  height: 100%
}

.max-vh {
  min-height: 100vh;
}

.input-box {
  position: relative;
  margin-bottom: 14px;
}
input::placeholder{
  opacity: 0.6!important;
} 
/* media queries */
@media only screen and (max-width: 767px) {
  main, #main-page-content {
    overflow: initial;
  }
  .content-max-width, .content-full-width {
    text-align: center;
  }
  .content-row {
    flex-direction: column;
  }
  .content-row.reverse-on-mobile {
    flex-direction: column-reverse;
  }
  .mobile-width-2-3 {
    width: calc(100vw * 2 / 3);
  }
  .mobile-width-3-4 {
    width: calc(100vw * 3 / 4);
  }
  .mobile-width-3-5 {
    width: calc(100vw * 3 / 5);
  }
  .unstyled-list-on-mobile {
    list-style-type: none;
  }
  .sticky-on-mobile {
    position: sticky;
    bottom: 30px;
  }
  .dark-section .sticky-on-mobile .button-border.outline {
    background-color: #000;
    box-shadow: 0px 0px 10px 15px #000;
  }
  .hidden-on-mobile {
    display: none!important;
  }
  .sm-mt-16 {
    margin-top: 16px;
  }
  .content-to-bottom {
    margin-top: 0px;
  }
}

.small .button-wrap .button-text {
  padding: 0px;
  margin: 0px;
}